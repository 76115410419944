/* styles.css */
.vehicle .vehicle-button {
    text-transform: none !important;
    font-size: 13px;
    border-radius: 8px;
    border: 1px solid  #09b294;
    background-color:  #09b294;
}

.vehicle .vehicle-button:hover {
    background-color: white; 
    color: black; 
  }

.vehicle .mdi-apps{
color: #09b294 !important;
}

.vehicle .dropdown-toggle{
    color: #09b294 !important;
}
.vehicle .pagination{
    color: #09b294 !important;
}


  