.items p {
    font-weight: 400;
    font-size: 14px;
    line-height: 2.5;
}

.request-wrapper {
    padding: 50px 0 80px;
}

@media (max-width: 768px) {
    .request-wrapper {
        padding: 10px 0;
    }
}