select {
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 6px;
}

/* select:focus {
    border: none;
    outline: none;
} */

._input {
    width: 90%;
}

.input-wrap {
    width: 100%;
    background: white;
    border-radius: 8px;
}

.input-wrap input {
    padding: 12px 15px;
    background: transparent;
    border: none;
}

.input-wrap-2{
    padding: 12px 15px;
    width: 100%;
    background: white;
    border-radius: 8px;
    border: 1px solid #09b294;
}

.input-wrap input:focus, .input-wrap-2:focus {
    outline: none;
}

.input-number input {
    width: 100%;
    padding: 12px 15px;
    border: none;
    border-radius: 6px;
}
.number-input-wrap input:focus {
    border-radius: 6px;
    border: none;
    outline: none;
}

.btn_wrap button {
    border-radius: 6px;
    border: none;
    font-size: 13px;
    font-weight: 700;
    color: #fff;
    background: #09b294;
}
.btn_wrap button:hover {
    /* border: 1px solid #09b294;
    color: #09b294; */
    background: #098a72;
    color: #ffffff;
}

input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

@media screen and (max-width:576px) {
    .btn_wrap button {
        width: 100%;
        padding: 15px 0;
        border-radius: 6px;
        border: none;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        background: rgb(9,178,148);
    }
}