.hero {
    overflow: hidden !important;
}

.hero .heroWrap {
    /* z-index: 5; */
    position: relative;
    padding-bottom: 100px;
}

.hero .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    /* z-index: 5; */
    background-color: rgba(0, 0, 0, .2);
    /* visibility: hidden; */
    opacity: 0;
    transition: 1s;
}

.hero .overlay.active {
    visibility: visible;
    opacity: 1;
}

.hero-img img {
    margin-top: 8em !important;
}

.form_input {
    overflow: hidden !important;
    top: -21%;
    right: -55%;
    bottom: -40%;
    margin-right: 0;
    transform: rotate(-12deg);
    border-radius: 7%;
    border: 1px solid transparent;
    background: rgb(42,64,127);
    position: absolute;
}

._form {
    position: absolute;
    transform: rotate(12deg);
    background: transparent;
    top: 200px;
    left: 4%;
    z-index: 2;
    /* border: 1px solid white; */
    max-width: 400px;
}

._form h2,
._form p {
    color: #fff !important;
    font-family: "Montserrat", sans-serif !important;
}

._form h2 {
    font-weight: 700;
    font-size: 40px;
}

._form .telephone {
    font-weight: 300;
    transition: .2s;
    font-size: 40px;
}

._form .trust-the-leading-operator {
    font-size: 20px;
    font-weight: 300;
}

._form .contact {}

._form svg {
    color: rgb(138, 162, 201);
}

._form .telephone:hover {
    color: #09b294;
}

._form option {
    padding: 18px;
}

@media screen and (max-width:1200px) {
    .form_input {
        transform: rotate(0deg);
        position: relative;
        right: 0;
        border-radius: 0 0 8px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    ._form {
        top: 150px;
        position: relative;
        transform: rotate(0deg);
        left: 0;
        margin-bottom: 300px;
    }

    .heroWrap {
        flex-direction: column-reverse;
    }

    .hero-img {
        width: 100% !important;
        margin: 0 auto;
        /* border: 1px solid; */
    }
}

@media screen and (max-width:768px) {
    ._form {
        top: 120px;
        margin-bottom: 150px;
    }

    ._form h2 {
        font-size: 28px;
    }

    ._form .telephone {
        font-size: 20px;
    }

    ._form svg {
        width: 22px  !important; 
    }

    ._form .trust-the-leading-operator {
        font-size: 16px;
        width: 70%;
    }

    ._form  form{
        margin-top: 30px
    }

    .hero .heroWrap {
        padding-bottom: 30px;
    }
}

