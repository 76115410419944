.order-title {
    padding: 15px 0 0 !important;
}

.order-details-wrapper p{
    margin: 0;
    line-height: 2;
}

.order-details-wrapper p span b {
    opacity: .8;
}

.order-details-wrapper p span {
    margin-left: 15px;
}

.order-details-wrapper i {
    font-weight: 600;
}

.map-frame {
    border:0; 
    width: 100%; 
    height: 70vh;
}

@media (max-width: 768px) {
    .map-frame {
        border:0; 
        width: 100%; 
        height: 50vh;
    }
}