@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    box-sizing: border-box !important;
}

body {
    overflow-x: hidden !important;
}

.my-6 {
    margin: 8em 0;
}

@media screen and (min-width:768px) {
    .text-md-uppercase {
        text-transform: uppercase !important;
    }  
}