.address-control, .special{
	font-size: 14px;
	padding-left: 7px;
}

.address-control span, .special span {
	font-weight: 600;
	cursor: pointer;
}

.address-control span:first-child {
	color: yellow;
}

.special span:first-child{
	color: #ff8800 !important;
}

.address-control span:last-child {
	color: #cbfbff;
}

.special span:last-child{
	color: #2a9672 !important;
}