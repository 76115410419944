.order-head h4{
    font-size: 30px;
    color: #4154f1;
}

.order-head{
    align-items: center;
    margin-top: 20px;
}

.order-head p {
    font-size: 16px;
    font-weight: 600;
}

.order h3 {
    font-size: 16px;
    color: #012970;
    font-weight: 700;
    margin: 10px 0;
}

.contact .card {
    border-radius: 10px;
 }

.order p {
    padding: 0;
    line-height: 20px;
    font-size: 12px;
    margin-bottom: 0;
}

.order  .btn {
    font-size: 12px !important;
}

.order-delivery p{
    font-size: 10px;
    font-weight: 600;
}  

.order-delivery p:first-child i {
    color: rgb(255, 0, 0);
    font-weight: 600;
}

.order-delivery p:last-child i {
    color: rgb(191, 0, 255);
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    .order-head h4{
        font-size: 20px;
    }

    .order h3 {
        font-size: 13px;
        line-height: 2.0;
        margin: 0;
    }
    .order p {
        line-height: 18px;
    }

    .order-amount{
        font-size: 12px;
    }

    .contact .card-body {
        padding: 0 10px 20px 10px;
    }

    .contact .card {
       border-radius: 10px;
    }
}