.testimonials {
    padding: 30px 0 110px;
    background-color: #f9f8f8;
    box-sizing: border-box;
}

.testimonials h3 {
    margin-bottom: 70px !important;
    color: #151515;
}

/* .clients-data {
    position: relative;
    height: 433.8px;
    z-index: 0;
    display: block;
    min-height: 160px;
    margin-left: -15px;
    margin-right: -15px;
    transition: .4s all ease;
} */

.quotes {
    position: relative;
    background: #ffffff;
    border-radius: 9px;
    box-shadow: 0 2px 16px 0 rgba(75, 81, 91, 0.14);
    margin-bottom: 33px;
    text-align: left;
}

/* .testimony {
    position: absolute;
    left: 0px;
    top: 0px;
} */

.quotes-cite {
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    color: #151515;
}

.quotes_head {
    padding: 30px 30px 20px 45px;
}

.quotes-info {
    display: flex;
    align-items: center;
    margin-left: -18px;
    /* padding: 30px 0; */
}

.quotes-info-main p {
    color: #2a4696;
    margin-bottom: 0;
}

img {
    display: inline-block;
    height: auto;
}


.quotes-info > * {
    margin-left: 0;
} 

.quotes_img {
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 20px;
}

.quotes_head + .quote {
    border-top: 1px solid #ebebeb;
}

.quote {
    padding: 10px;
}

.quote-text {
    line-height: 1.625;
    max-width: 320px;
    padding: 22px 20px;
}

.quote_logo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 20px;
}

.quotes::after {
    bottom: -22px;
    left: 62px;
    border-width: 22px 32px 0 0;
    border-color: #ffffff transparent transparent transparent;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
}

.quote-time {
    margin-left: 10px;
    /* padding: 22px -60px; */
}

.contain {
    padding: 0 4.3rem !important;
}

@media screen and (max-width: 1200) {
    .contain {
        max-width: 80%;
    }
}

@media screen and (max-width: 768px) {
    .contain {
        max-width: 540px;
    }
}