.map-info-wrapper  {
    width: 80%;
    margin: 0 auto;
}

.map-info-wrapper h4 {
    font-size: 25px;
    font-weight: 900;
    margin-bottom: 45px;
}

.map-wrapper {
    width: 100%;
    height: 300px;
    border-radius: 15px;
    background-color: antiquewhite;
    margin-bottom: 27px;
}

.confirm-button .btn {
    border-radius: 50px;
    text-transform: capitalize !important;
    width: 50%;
    margin: 40px 0;
}

@media (max-width: 768px) {
    .map-info-wrapper  {
        width: 100%;
    }
}