.careers {
    position: relative;
    padding: 80px 0 30px;
    background: #2a4696 url('/public/assets/images/gas-flame.png')center center;
    background-size: cover;
}

.career {
    background: #f9f8f8;
    padding: 0 10px;
    text-align: center;
}

ol {
    padding-left: 1rem ;
    font-size: 17px;
}


.career_list {
    position: relative;
    top: -12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 12px 10px 12px;
    background: #f9f8f8;
    border-radius: 4px;
    transform: translate3d(0, -3px, 0);
    margin-bottom: -3px;
    margin-left: -17px;
    margin-right: -17px;
    list-style: none;  
    /* bottom: 10px; */
}
.career_list li {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    line-height: 1.7;
    font-weight: 500;
}

.career_list li ::after{
    content: "/";
    position: absolute;
    top: 50%;
    right: -5px;
    display: inline-block;
    font-size: inherit;
    font-style: normal;
    transform: translate3d(0, -50%, 0);
}

.button {
    position: relative;
    overflow: hidden;
    display: inline-block;
    padding: 17px 35px;
    font-size: 14px;
    line-height: 1.4;
    border: 1px solid;
    border-radius: 4px;
    font-weight: 700;
    letter-spacing: 0.01em;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    transition: 250ms all ease-in-out;
    color: #ffffff;
    background-color: #09b294;
    border-color: #09b294;
}

.button:hover {
    color: #ffffff; 
    background-color: #1c3ca8; 
    cursor: pointer;
}

.career-start a {
    text-decoration: none;
}

img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}

li a {
    text-decoration: none;
    cursor: pointer;
}
  .career_list a {
    color: #000;
}

.career_list > * {
    margin-top: 3px;
    padding: 0 17px;
}

.career-details {
    padding: 80px 0 30px;
}

/* .career-contain {
    padding: 0 30px;
} */



.career-details .career-start p {
    font-size: 18px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 300;
    letter-spacing: 0.5px;
}

.career-details .career-start ol li {
    font-size: 17px;
    color: #151515;
    font-weight: 400;
    letter-spacing: 0.4px;
    line-height: 20.4px;

}

.lists {
    columns: 2;
    column-gap: 30px;
    break-inside: avoid;
    max-width: 400px;
    padding-left: 1rem !important;
}

.details {
    margin-bottom: -50px;
}

.details > * {
    margin-bottom: 50px;
}

.vacancies {
    background-color: #f9f8f8;
    padding:80px 0 110px;
}

/* .career-start {
    padding: 0 20px ;
} */


.vacant a {
    text-decoration: none;
    color: #000000cc;
}

.vacancy {
    position: relative;
    display: block;
    width: 100%;
    padding: 35px 25px 35px;
    border-radius: 6px;
    background: #ffffff;
    box-shadow: 1px 2px 5px 0 rgba(68, 73, 83, 0.12);
    /* font-size: 26px; */
}
.vacancy p {
    font-weight: 300;
    color: #151515;
    font-size: 26px;
}

.vacancy-list {
    margin-bottom: -4px;
    margin-left: -10px;
    padding: 0;
}

.vacancy-list li {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 10px;
    margin-left: 11px;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
}

.vacancies h3 {
    font-size: 23px;
    line-height: 1.35;
    letter-spacing: 0;
}


.copyright {
    border-top: 1px solid #ebebeb;
    /* margin-bottom: 20px; */
}

ul {
    list-style: none;
}

@media (min-width:1200px) {
    .careers {
        padding: 80px 0 110px;
    }
    .vacancy {
        font-size: 26px;
    }

    .career-details .career-start h3 {
        font-size: 37px;
        line-height: 1.2973;
    }
}

.career-img + .career-details {
    border-top: 1px solid #ebebeb;
}


@media (max-width:768px) {
    .vacancy {
        padding: 35px 9% 45px;
    }
    
}

/* @media screen and (min-width:768px) {
    .career-details .career-start h3 {
        font-size: 30px;
    }
} */

@media screen and (max-width:1200px)  {
    .car {
        padding-left: 45px;
    }
}

@media screen and (max-width:400px) {
    .vacancy-list li span {
        font-size: 14px;
    }
}

@media screen and (max-width: 576px) {
    .vacancy-list li span {
        font-size: 14px;
    }
}


@media screen and (max-width: 992px) {
    .career-details {
        margin-bottom: 50px;
    }

    .career-details .career-start p {
        font-size: 15px;
    }

    .car {
        padding-left: 0px;
    }

    .vacancies {
        padding: 30px 0;
    }
    .vacancy p {
        font-weight: 300;
        color: #151515;
        font-size: 20px;
        margin-bottom: 40px;
    }
}




