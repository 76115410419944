.navBar {
    position: fixed;
    z-index: 9999;
    transition: .2s all ease;
    box-sizing: border-box;
}

.navBar .child {
    margin: 0 6em;
}

.links a {
    text-decoration: none !important;
    color: #000;
    font-size: 16px;
    font-weight: 500;
}


.hamburger-major {

}

.ham-major-wrapper {
    position: relative;
    width: 30px;
    height: 30px;
    /* border: 1px solid #000; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.ham-major-item {
    width: 90%;
    height: 3px;
    background: #000;
    position: relative;
}

.ham-major-item::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    top: 8px;
    background: #000;
    transition: .2s all;
}
.ham-major-item::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    top: -8px;
    background: #000;
    transition: .2s all;
}

.ham-major-item.onclick {
    background: #fff;
}

.ham-major-item.onclick::before {
    top: 0;
    transform: rotate(-45deg);
}

.ham-major-item.onclick::after {
    top: 0;
    transform: rotate(-135deg);
}



.hamburger {
    position: relative;
}

.hamburger .wrap {
    width: 50px;
    height: 50px;
    /* border: 1px solid white; */
    cursor: pointer;
}

.hamburger .wrap div {
    width: 65%;
    height: 2px;
    background: #ebebeb;
    margin-top: 5px;
}

.hamburger .wrap div::before {
    position: absolute;
    content: "";
    background-color: #ebebeb;
    height: 2px;
    width: 40%;
    bottom: 50%;
    transition: .2s ease;
    left: 17%;
    /* right: auto; */
}
.hamburger .wrap div::after {
    position: absolute;
    content: "";
    background-color: #ebebeb;
    height: 2px;
    width: 65%;
    bottom: 30%;
}

.hamburger .wrap:hover .hamburger-item::before {
    left: 42%;
    /* right: 19%; */
}

.nav_links {
    transition: .2s ease;
    
} 

.nav_links a {
    background: transparent;
    color: white;
    font-weight: 700;
    border: 1px solid #fff !important;
    padding: 10px 15px;
    margin: 10px 20px;
    border-radius: 5px;
    transition: .2s;
}

.nav_links button:hover {
    background:  #09b294;
    border-color:  #09b294 !important;
}

@media screen and (min-width:1200px) {

.navBar.scroll {
    background: #fff;
    padding: 0 1px !important;
    transition: .2s ease;
    /* margin: 0 !important; */
}

.nav_links.scroll {
    transition: .2s ease;
    clip-path: polygon(4% 0, 100% 0, 100% 100%, 0% 100%);
    /* padding: 0 100px 0 25px; */
    border-radius:;
    position: absolute;
    right: 0;
    width: 25%;
    height: 100%;
    background: #183399 !important;
    /* border-bottom: 1px solid #183399; */
    z-index: 888;
}    
}


@media screen and (max-width:1200px) {
    .navBar {
        background-color: #fff;
        padding-right: 0 !important;
    }
    .navBar.scroll {
        border-bottom: 1px solid #ebebeb;
    }
    .navBar .child {
        padding: 0 !important;
        margin: 0 0;
    }
    .navBar .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .nav_links {
        /* position: ; */
        margin-right: 0;
        padding: 0px 13px 0 0 !important;
        background:#2a4696;
        height: inherit;
        /* padding: 0 13px; */
        /* border: 3px solid goldenrod; */
    }
    .nav_links button {
        background: transparent;
        color: #ebebeb;
        font-weight: 700;
        border: none !important;
        margin-right: 20px;
    }
}
@media screen and (max-width:768px) {
    .nav_links button {
        background: transparent;
        color: white;
        font-weight: 700;
        /* border: 1px solid #fff !important; */
        padding: 7px 12px;
        margin: 7px 0px;
        border-radius: 5px;
    }
}

@media screen and (max-width:460px) {
    .nav_links {
        padding-right:  0px !important;
    }
}
