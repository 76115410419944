.Support button{
    background: #017245 !important;
    border: 0 !important;
    padding: 12px 34px !important;
    color: #fff !important;
    transition: 0.4s !important;
    border-radius: 50px !important;
    cursor: pointer !important;
    text-transform: none !important;
}

.Support:hover button {
    background-color: #046941 !important;
}

.Support .info i {
    font-size: 20px;
    color: #017245;
    float: left;
    width: 44px;
    height: 44px;
    background: #e7f5fb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    pointer-events: auto;
   
}

.Support .email{
    line-height: 2px;
}
.Support .phone{
    line-height: 2px;
}

[data-aos].aos-animate {
    pointer-events: auto;
}

.Support .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #37517e;
}

.Support .info p {
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #6182ba;
    
}

.Support .form-group {
    margin-bottom: 20px;
}

.Support .info {
    border-top: 3px solid #017245;
    border-bottom: 3px solid #017245;
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
}


.Support .contact{
    border-top: 3px solid #017245;
    border-bottom: 3px solid #017245;
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
}