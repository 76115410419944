.success-card {
    text-align: center;
}
.success-card h1 {
    color: #88B04B;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    font-size: 30px;
    margin: 15px auto 10px;
}

.success-card p {
    color: #404F5E;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    margin: 0;
}

.success-icon i {
    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left: -15px;
}

.success-card {
    background: white;
    padding: 60px;
    border-radius: 7px;
    /* box-shadow: 0 2px 3px #C8D0D8; */
    display: inline-block;
    margin: 0 auto;
}

.success-icon {
    border-radius:200px; 
    height:200px; 
    width:200px; 
    background: #F8FAF5; 
    margin:0 auto;
}

@media screen and (max-width: 768px) {
    .success-card {
        padding: 60px 15px;
    }

    .success-card h1 {
        font-size: 25px;
    }

    .success-card p {
        font-size: 14px;
    }
}