/* styles.css */
.history .history-button {
    text-transform: none;
    font-size: 13px;
    border-radius: 8px;
    border: 1px solid  #09b294;
    background-color:  #09b294;
}

.history .history-button:hover {
    background-color: white; 
    color: black; 
  }

.history .mdi-apps{
color: #09b294 !important;
}

.history .dropdown-toggle{
    color: #09b294 !important;
}
.history .pagination{
    color: #09b294 !important;
}

.bg-primary-light{
    color: blue;
    background-color: #0000ff38 !important;
    font-weight: 600;
    text-transform: none;
    font-size: 10px;
}

.bg-secondary{
    font-weight: 600;
    text-transform: none;
    font-size: 10px;
}

.bg-info{
    font-weight: 600;
    text-transform: none;
    font-size: 10px;
}

.bg-success{
    font-weight: 600;
    text-transform: none;
    font-size: 10px;
}

.bg-danger{
    font-weight: 600;
    text-transform: none;
    font-size: 10px;
    background-color: ff0000ba !important;
    color: #fefcfc !important;
}



  