.suggestion-wrapper {
    box-shadow: 1px 2px 5px 0 rgba(68, 73, 83, 0.12);
    border-radius: 7px;
    position: absolute;
    margin-top: 10px;
    background-color: white;
    /* width: 50%; */
}

.suggestion-wrapper ul {
    padding: 12px 0 0;
    /* padding: 12px 10px !important; */
}

.suggestion-wrapper ul li{
    font-size: 13px;
    line-height: 2.5;
    padding: 0 10px !important;
    margin: 0;
}

.suggestion-wrapper ul li:hover {
    background-color: aliceblue;
}

@media screen and (max-width: 768px) {
    .suggestion-wrapper {
        width: 90% ;
    } 
}