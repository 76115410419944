.card-header {
    border-bottom: 1px solid #f8f8f9;
}

.items {
    justify-content: space-between;
}

.items p{
    font-weight: 400;
    font-size: 14px;
    line-height: 2.5;
}

._form-2 .input-wrap input, ._form-2 select {
    /* padding: 12px 15px !important; */
    background: transparent;
    border: 1px solid #09b294;
    width: 100% !important;
    border-radius: 6px !important;
}