.footers ul, .footers li {
    margin: 0 !important;
    padding: 0 !important;
}
.footers ul {
    margin-top: 30px !important;
    list-style: none;
}
.footers ul li {
    margin: 15px 0 !important;
}
.footers ul li a {
    color: #000000cc;
}

.footers h5 {
    font-family: inherit !important;
}

.footers a {
    text-decoration: none;
    color: #000000cc;
}
.footers {
    padding:10px 0 0px;
    font-family: inherit !important;
}

.footers button {
    border: 1px solid black;
    padding: 10px 25px;
    font-size: 13px;
    font-weight: 700;
    color: #151515;
    letter-spacing: .13px;
    transition: .2s;
    font-family: inherit !important;
}

.footers button:hover {
    background-color:  #09b294;
    color:  #fff;
    border-color:  #09b294;
    background: #09b294;
}
 /* .footers .join {
    background: transparent;
    color: white;
    font-weight: 700;
    border: 1px solid #fff !important;
    padding: 10px 15px;
    margin: 10px 20px;
    border-radius: 5px;
}  */

/* .buttons {
    padding: 10px 50px;
    border-radius: 30px;
} */

/* .footers ul .signup,.footers ul .pricing {
    margin-top: 0 !important;
} */

 .rowss {
    margin-bottom: -50px;
}

.rowss > * {
    margin-bottom: 50px;
}


@media (min-width:768px) {
    .footers-one {
        padding: 70px 0 60px;
    }
    
}

@media (min-width:992px) {
    .rowss{
       justify-content: space-between !important;
    }
    
}

@media (min-width:1200px) {
    .footers .footers-one + * {
        margin-top: 38px;
       
    }    
}
@media screen and (max-width:1200px) {
    .group {
        margin-top: 1rem !important;
    }
}


@media screen and (max-width:576px) {
    .footers ul li {
        display: inline-block;
        margin: 0 .75em !important;
    }
}

@media screen and (min-width:576px) {
    .footer_services {
        columns: 2;
        break-inside: avoid;
        column-gap: 30px;
    }
}

.footers ul .signup {
    margin-top: 0 !important;
}

.infos {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
}


